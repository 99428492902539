import React, { useState, useEffect } from 'react';
import { doc, setDoc } from 'firebase/firestore';
import { db, auth } from '../firebase';
import { toast } from 'react-toastify';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { v4 as uuidv4 } from 'uuid';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import statesData from '../data/States.json';

const NewLibrary = ({ onClose, onAddLibrary, initialData }) => {
    const [libraryData, setLibraryData] = useState({
        name: '',
        country: '',
        state: '',
        district: '',
        pincode: '',
        address: '',
        phoneNumber: '',
        totalSeats: '',
        description: '',
    });

    const [admin, setAdmin] = useState({ email: '', password: '' });
    const [creating, setCreating] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [slots, setSlots] = useState([{ time: '', price: '' }]);
    const [districts, setDistricts] = useState([]);

    useEffect(() => {
        if (initialData) {
            setLibraryData({
                name: initialData.name || '',
                country: initialData.country || '',
                state: initialData.state || '',
                district: initialData.district || '',
                pincode: initialData.pincode || '',
                address: initialData.address || '',
                phoneNumber: initialData.phoneNumber || '',
                totalSeats: initialData.totalSeats || '',
                description: initialData.description || '',
            });
            setAdmin({ email: initialData.email || '', password: '' });
            
            // Set districts based on the initial state
            if (initialData.state) {
                const foundState = statesData.states.find(state => state.state === initialData.state);
                if (foundState) {
                    setDistricts(foundState.districts);
                }
            }
        }
    }, [initialData]);

    const handleStateChange = (e) => {
        const selectedState = e.target.value;
        setLibraryData({
            ...libraryData,
            state: selectedState,
            district: ''
        });

        const foundState = statesData.states.find(state => state.state === selectedState);
        if (foundState) {
            setDistricts(foundState.districts);
        } else {
            setDistricts([]);
        }
    };

    const handleLibraryChange = (e) => {
        setLibraryData({
            ...libraryData,
            [e.target.name]: e.target.value,
        });
    };

    const handleAdminChange = (e) => {
        setAdmin({
            ...admin,
            [e.target.name]: e.target.value,
        });
    };

    const handleSlotChange = (index, e) => {
        const updatedSlots = [...slots];
        updatedSlots[index][e.target.name] = e.target.value;
        setSlots(updatedSlots);
    };

    const addSlot = () => {
        setSlots([...slots, { time: '', price: '' }]);
    };

    const removeSlot = (index) => {
        const updatedSlots = [...slots];
        updatedSlots.splice(index, 1);
        setSlots(updatedSlots);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setCreating(true);

        try {
            const libraryId = uuidv4();
            const joinDate = new Date();

            await setDoc(doc(db, 'libraries', libraryId), {
                libraryId,
                ...libraryData,
                ownerId: auth.currentUser.uid,
                adminEmail: admin.email,
                joinDate,
                slots
            });

            const seatStatsData = {};
            slots.forEach(slot => {
                seatStatsData[slot.time] = [];
            });

            await setDoc(doc(db, 'seat_stats', libraryId), {
                libraryId,
                totalSeats: libraryData.totalSeats,
                slots: seatStatsData
            });

            const userCredential = await createUserWithEmailAndPassword(auth, admin.email, admin.password);
            const userId = userCredential.user.uid;

            await setDoc(doc(db, 'users', userId), {
                email: admin.email,
                libraryId,
                name: libraryData.name,
                role: 'admin',
                joinDate,
            });

            toast.success('Library and admin added successfully.');
            onAddLibrary({ id: libraryId, ...libraryData, adminEmail: admin.email, slots });

            onClose();
        } catch (error) {
            console.error('Error adding library:', error);
            toast.error('Failed to add library: ' + error.message);
        } finally {
            setCreating(false);
        }
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-white p-8 rounded-lg shadow-md max-w-2xl w-full overflow-hidden">
                <div className="overflow-y-auto max-h-[80vh]">
                    <h2 className="text-3xl font-semibold mb-8 text-center">
                        {initialData ? 'Admit New Library' : 'Add New Library'}
                    </h2>
                    <form onSubmit={handleSubmit}>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-4">
                            <div>
                                <label className="block text-gray-700">Library Name</label>
                                <input
                                    type="text"
                                    name="name"
                                    value={libraryData.name}
                                    onChange={handleLibraryChange}
                                    className="w-full px-4 py-2 border rounded-lg"
                                    required
                                />
                            </div>
                            <div>
                                <label className="block text-gray-700">Country</label>
                                <input
                                    type="text"
                                    name="country"
                                    value={libraryData.country}
                                    onChange={handleLibraryChange}
                                    className="w-full px-4 py-2 border rounded-lg"
                                    required
                                />
                            </div>
                            <div>
                                <label className="block text-gray-700">State</label>
                                <select
                                    name="state"
                                    value={libraryData.state}
                                    onChange={handleStateChange}
                                    className="w-full px-4 py-2 border rounded-lg"
                                    required
                                >
                                    <option value="">Select State</option>
                                    {statesData.states.map((state, index) => (
                                        <option key={index} value={state.state}>
                                            {state.state}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div>
                                <label className="block text-gray-700">District</label>
                                <select
                                    name="district"
                                    value={libraryData.district}
                                    onChange={handleLibraryChange}
                                    className="w-full px-4 py-2 border rounded-lg"
                                    required
                                    disabled={!districts.length}
                                >
                                    <option value="">Select District</option>
                                    {districts.map((district, index) => (
                                        <option key={index} value={district}>
                                            {district}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div>
                                <label className="block text-gray-700">Pincode</label>
                                <input
                                    type="text"
                                    name="pincode"
                                    value={libraryData.pincode}
                                    onChange={handleLibraryChange}
                                    className="w-full px-4 py-2 border rounded-lg"
                                    required
                                />
                            </div>
                            <div>
                                <label className="block text-gray-700">Address</label>
                                <textarea
                                    name="address"
                                    value={libraryData.address}
                                    onChange={handleLibraryChange}
                                    className="w-full px-4 py-2 border rounded-lg"
                                    rows={3}
                                    required
                                />
                            </div>
                            <div>
                                <label className="block text-gray-700">Phone Number</label>
                                <input
                                    type="tel"
                                    name="phoneNumber"
                                    value={libraryData.phoneNumber}
                                    onChange={handleLibraryChange}
                                    className="w-full px-4 py-2 border rounded-lg"
                                    required
                                />
                            </div>
                            <div>
                                <label className="block text-gray-700">Total Seats</label>
                                <input
                                    type="number"
                                    name="totalSeats"
                                    value={libraryData.totalSeats}
                                    onChange={handleLibraryChange}
                                    className="w-full px-4 py-2 border rounded-lg"
                                    required
                                />
                            </div>
                            <div className="col-span-2">
                                <label className="block text-gray-700">Description</label>
                                <textarea
                                    name="description"
                                    value={libraryData.description}
                                    onChange={handleLibraryChange}
                                    className="w-full px-4 py-2 border rounded-lg"
                                    rows={4}
                                    required
                                />
                            </div>
                        </div>

                        <div className="mb-4">
                            <h3 className="text-lg font-semibold mb-2">Time Slots and Pricing</h3>
                            {slots.map((slot, index) => (
                                <div key={index} className="grid grid-cols-3 gap-4 mb-4">
                                    <input
                                        type="text"
                                        name="time"
                                        value={slot.time}
                                        onChange={(e) => handleSlotChange(index, e)}
                                        placeholder="Time Slot (e.g., 6am - 11am)"
                                        className="col-span-2 px-4 py-2 border rounded-lg"
                                        required
                                    />
                                    <input
                                        type="number"
                                        name="price"
                                        value={slot.price}
                                        onChange={(e) => handleSlotChange(index, e)}
                                        placeholder="Price"
                                        className="px-4 py-2 border rounded-lg"
                                        required
                                    />
                                    {index > 0 && (
                                        <button
                                            type="button"
                                            onClick={() => removeSlot(index)}
                                            className="text-red-500"
                                        >
                                            Remove
                                        </button>
                                    )}
                                </div>
                            ))}
                            <button
                                type="button"
                                onClick={addSlot}
                                className="px-4 py-2 bg-blue-600 text-white rounded-lg"
                            >
                                Add Another Slot
                            </button>
                        </div>

                        <div className="mb-4">
                            <label className="block text-gray-700">Admin Email</label>
                            <input
                                type="email"
                                name="email"
                                value={admin.email}
                                onChange={handleAdminChange}
                                className="w-full px-4 py-2 border rounded-lg mb-2"
                                required
                            />
                            <label className="block text-gray-700">Admin Password</label>
                            <div className="relative">
                                <input
                                    type={showPassword ? 'text' : 'password'}
                                    name="password"
                                    value={admin.password}
                                    onChange={handleAdminChange}
                                    className="w-full px-4 py-2 border rounded-lg mb-2"
                                    required
                                />
                                <button
                                    type="button"
                                    onClick={() => setShowPassword(!showPassword)}
                                    className="absolute inset-y-0 right-0 px-3 flex items-center"
                                >
                                    {showPassword ? (
                                        <FaEyeSlash className="w-5 h-5 text-gray-500" />
                                    ) : (
                                        <FaEye className="w-5 h-5 text-gray-500" />
                                    )}
                                </button>
                            </div>
                        </div>

                        <div className="mt-6 flex justify-end">
                            <button
                                type="button"
                                onClick={onClose}
                                className="px-4 py-2 bg-gray-400 text-white rounded-lg mr-4"
                            >
                                Cancel
                            </button>
                            <button
                                type="submit"
                                className="px-4 py-2 bg-blue-600 text-white rounded-lg"
                                disabled={creating}
                            >
                                {creating ? 'Creating...' : (initialData ? 'Admit Library' : 'Create Library')}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default NewLibrary;